// Fonts
$main-font: 'Open Sans', Arial, Helvetica, sans-serif;
$semibold-font: 'Open Sans Semibold', Arial, Helvetica, sans-serif;
$bold-font: 'Open Sans Bold', Arial, Helvetica, sans-serif;

// Colors
$teal: #009FA1;
$dark-slate: #2D3E47;
$orange: #F38C0A;
$gold: #FFCC33;
$error-red: #D0011B;
$light-gray: #AAADAE;
$medium-gray: #6C685E;
$thunderbird: #D41411;
$success: #75c700;

// Gradients
$orange-gradient: linear-gradient( #FCBD03 0%, #F38C0A 100%);
$orange-gradient-reverse: linear-gradient( #F38C0A 0%, #FCBD03 100%);
$gray-gradient: linear-gradient(-180deg, #B7B7B7 4%, #8A8A8A 98%);

// Dimensions
$header-height: 68px;
