@import "src/variables";

.flex-form-header-container {
    .vpg-label {
        width: 100%;
    }

    .vpg-label-text {
        text-align: center;
        line-height: normal;
        font-size: 14px;
    }

    .vpg-completed-wrapper {
        background-color: #BDE394;
    }
}

.vpg-p-btn,
.vpg-phone-number-label,
.vpg-phone-number-input-text-field,
.vpg-phone-number-dropdown-placeholder,
.vpg-phone-number-country-code,
.vpg-phone-number-country-name,
.vpg-phone-number-checkmark,
.vpg-search-field,
.vpg-search-result-item-body-section,
.vpg-search-no-results-body,
.vpg-dropdown-input-field,
.vpg-dropdown-placeholder-selected-value,
.vpg-label-text-padding,
.vpg-dropdown-no-results-body,
.vpg-search-no-results-header,
.vpg-dropdown-no-results-header,
.vpg-checkbox-label-right,
.vpg-link-default,
.vpg-dropdown-text-container {
    font-size: 1rem !important;
}

.vpg-dropdown-input-field-placeholder-not-active {
    ::placeholder {
        font-size: 1rem !important;
    }
}

.vpg-search-bar-icon {
    font-size: 1.2rem !important;
}

.vpg-dropdown-main .vpg-dropdown-text-container-wrapper input {
    font-size: 1rem !important;
}

.vpg-text-field-character-limit {
    display: none !important;
}

.vpg-label {
    margin: 0 !important;
}

.groove-wrapper {
    .vpg-text-field-component-wrapper {
        min-width: 0 !important;
        max-width: none !important;
        padding: 0 !important;
    }

    .vpg-input-text-field {
        width: 100% !important;
        font-size: 1rem !important;
    }

    .vpg-input-label {
        letter-spacing: 0;
        font-size: 1rem !important;
    }

    .vpg-search-wrapper {
        min-width: 0 !important;
        max-width: none !important;
        padding: 0 !important;
    }

    .vpg-search-container:hover {
        padding: 12px 9px !important;
    }

    .vpg-search-container:focus-within {
        padding: 12px 9px !important;
    }

    .vpg-search-results {
        top: 70px !important;
    }

    .v-checkbox .v-checkbox-label {
        font-size: 1rem;
        margin-inline-start: 20px;
        text-transform: none;
        color: #000;
        font-family: "Open Sans", sans-serif;
        line-height: 1.4;
        --checkbox-size: 3rem;
    }

    .vpg-checkbox-wrapper {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        margin-bottom: 0 !important;
        justify-content: normal !important;
    }

    .vpg-checkbox-label-right label {
        letter-spacing: normal;
        text-transform: none;
        font-size: 1rem;
        color: black;
        font-family: "Open Sans", sans-serif;
        margin-bottom: 0;
    }

    .dropdown-result-items {
        max-height: 180px !important;
    }

    .vpg-dropdown-placeholder,
    .vpg-dropdown-label,
    .vpg-dropdown-arrow-wrapper,
    .vpg-dropdown-result-item-body-section,
    .vpg-text-field-icon {
        font-size: 1rem !important;
    }
}

.checkbox-component {
    .v-checkbox {
        .v-checkbox-label {
            margin-inline-start: unset;
            align-items: flex-start;
        }

        .v-checkbox-input {
            display: none;
        }
    }
}

.v-checkbox.is-checked .v-checkbox-checkmark::after {
    bottom: 9px !important;
}

.faux-groove-label {
    color: #202020 !important;
    font-family: "Open Sans", sans-serif !important;
    font-weight: 700 !important;
    margin: 0 !important;
    text-transform: none !important;
}

.faux-groove-header4 {
    font-size: 16px !important;
    line-height: 21px !important;
}

.faux-groove-field-container {
    margin: 5px 0;
}

.faux-groove-input-field-container {
    height: 48px;
    margin: 5px 0;
}

.vpg-checkbox-label-link:hover {
    color: var(--VPGrooveColor-LinkColor);
}

.confirmation-checkmark {
    .vpg-icon-cmp {
        font-weight: bold;
        background: #5fa300;
        border-radius: 50%;
        padding: 39px;
        color: #ffffff;
    }
    span {
        font-size: 58px;
    }
}

.confirmation-checkmark-small {
    height: 40px;
    width: 40px;

    .vpg-icon-cmp {
        background: #5fa300;
        border-radius: 50%;
        color: #ffffff;
        font-weight: bold;
        padding: 10px;
    }
    span {
        font-size: 20px;
    }
}

.search-icon {
    .vpg-icon-cmp {
        position: absolute;
        top: 12px;
        inset-inline-start: 9px;
        z-index: 1;
        font-size: 20px;
        color: $medium-gray;
    }
}

.flex-form-error {
    .vpg-icon-container vpg-icon i.fal {
        font-size: 24px !important;
        font-weight: 100 !important;
    }

    vpg-inline-label .vpg-label-text {
        padding: 10pt 0 !important;
    }
}

#progress-panel {
    .vpg-spb-progress-line {
        min-height: 20px !important;
    }

    .vpg-spg-step {
        .vpg-spg-icon-container {
            font-size: 1rem !important;
        }
        .fa-file-signature {
            padding-inline-start: 5px;
        }
        .fa-user-edit {
            padding-inline-start: 5px;
        }
    }

    .vpg-spb-info {
        padding: 15px 0 15px 15px;
        max-width: 100%;
        margin-right: 10px;

        .vpg-spb-info-title {
            font-size: 1rem;
        }
    }

    .vpg-spb-info-description {
        max-width: 100%;
        font-size: 1rem;
    }
}

.vpg-required-indicator {
    font-size: 0.7rem !important;
    margin-inline-start: 5px !important;
}

.vpg-text-field-error, .vpg-dropdown-error {
    font-family: $semibold-font !important;
    font-size: 11px !important;
    color: $error-red !important;
    letter-spacing: 0.2px !important;
    line-height: 20px !important;
}

.vpg-dropdown-error {
    margin-bottom: 5px;
}

.vpg-input-text-field-invalid {
    border: 1px solid #333333 !important;
    padding: 13px 10px !important;
}

.vpg-dropdown-invalid {
    border: 1px solid #333333 !important;
}

.vpg-label-text-padding {
    a, a:hover {
        color: var(--VPGrooveColor-LinkColor) !important;
        font-weight: bold !important;
    }
}

.birthdate-warning {
    margin-top: 5px;
}

.spb-main .v-segmented-progress-bar-text {
    max-width: none;
}

.footer {
    .support {
        .support-button {
            .webchat-link {
                color: var(--v-color-neutral-pure-white);
                margin-right: 0;
            }
        }
    }
}
