@font-face {
    font-family: 'Open Sans';
    src: url('/assets/fonts/OpenSans-Regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans Semibold';
    src: url('/assets/fonts/OpenSans-Semibold-webfont.woff') format('woff');
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: 'Open Sans Bold';
    src: url('/assets/fonts/OpenSans-Bold-webfont.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}
